// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect } from 'react';

export default function SetTitle(title: string, plainTitle = false): void {
  useEffect(() => {
    if (title === '') {
      document.title = 'Manage authenticators - Imprivata';
      document
        .getElementsByTagName('meta')
        ?.namedItem('description')
        ?.setAttribute('content', 'Manage authenticators - Imprivata');
    } else {
      document.title = plainTitle
        ? `${title}`
        : `${title} - Manage authenticators - Imprivata`;
      document
        .getElementsByTagName('meta')
        ?.namedItem('description')
        ?.setAttribute('content', document.title);
    }
  });
}
