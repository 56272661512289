// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics, Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { ApiError, AuthenticatorsPayload } from '../../../api/types';
import {
  enrollAuthenticator$,
  listAuthenticators$,
  unenrollAuthenticator$,
} from '../../../api/authenticatorsService';
import { RootAction } from '../../../store/rootAction';
import { RootState } from '../../../store/rootReducer';
import {
  enrollAuthenticatorActions,
  listAuthenticatorsActions,
  unenrollAuthenticatorActions,
} from './actions';
import { History } from 'history';
import { AUTHENTICATORS_ROUTE } from '../../../routers/route-names';
import { endWorkflowAction } from '../../workflow/store/actions';
import { addErrorHandlerAction } from '../../../error-handler/store/action';
import { getErrorMessageCode } from '../../../i18n/utils';
import { ERROR_CODES } from '../../../error-handler/constants';
import { redirectWithQuery } from '../../../utils/routingHelpers';
import { ENROLLED_BY_ANOTHER, QueryParamKey } from './constants';
import { ContextNames } from '../../../i18n';

export const listAuthenticatorsEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(listAuthenticatorsActions.request)),
    switchMap(({ payload }) =>
      listAuthenticators$(payload).pipe(
        map((res: unknown) => {
          return listAuthenticatorsActions.success(
            res as AuthenticatorsPayload,
          );
        }),
        catchError((err: ApiError) => {
          return of(
            listAuthenticatorsActions.failure(err),
            addErrorHandlerAction({ errorCode: err.code }),
          );
        }),
        takeUntil(
          action$.pipe(filter(isActionOf(listAuthenticatorsActions.cancel))),
        ),
      ),
    ),
  );

export const enrollAuthenticatorEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, _, { history }: { history: History }) =>
  action$.pipe(
    filter(isActionOf(enrollAuthenticatorActions.request)),
    switchMap(({ payload }) =>
      enrollAuthenticator$(payload.authenticatorEnrollRequest).pipe(
        switchMap(res => {
          history.push(AUTHENTICATORS_ROUTE + window.location.search);

          return from([
            enrollAuthenticatorActions.success(res),
            endWorkflowAction(),
          ]);
        }),
        catchError((err: ApiError) => {
          let errorCode = getErrorMessageCode(
            payload.authenticatorEnrollRequest.factorType === 'impr-id'
              ? ContextNames.IID_ENROLLMENT
              : ContextNames.PIN_ENROLLMENT,
            err.code,
          );
          const errorMessageParams =
            errorCode === ENROLLED_BY_ANOTHER && payload.iidSerialNumber
              ? ({ serialNumber: payload.iidSerialNumber } as Record<
                  string,
                  string
                >)
              : {};
          if (Object.values(ERROR_CODES).includes(errorCode as ERROR_CODES)) {
            redirectWithQuery(AUTHENTICATORS_ROUTE);
          } else if (!errorCode?.includes('enroll')) {
            errorCode = 'enroll-activate-failed';
          }
          return from([
            endWorkflowAction(),
            enrollAuthenticatorActions.failure(err),
            addErrorHandlerAction({
              errorCode,
              errorMessageParams,
            }),
          ]);
        }),
        takeUntil(
          action$.pipe(filter(isActionOf(enrollAuthenticatorActions.cancel))),
        ),
      ),
    ),
  );

export const unenrollAuthenticatorEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(unenrollAuthenticatorActions.request)),
    switchMap(({ payload }) =>
      unenrollAuthenticator$(payload).pipe(
        switchMap(res => {
          const tenantId: string =
            new URLSearchParams(window.location.search).get(
              QueryParamKey.TENANT_ID,
            ) || '';

          return from([
            unenrollAuthenticatorActions.success(res),
            listAuthenticatorsActions.request({ tenantId }),
            endWorkflowAction(),
          ]);
        }),
        catchError((err: ApiError) =>
          from([
            endWorkflowAction(),
            unenrollAuthenticatorActions.failure(err),
            addErrorHandlerAction({
              errorCode: err.code,
            }),
          ]),
        ),
        takeUntil(
          action$.pipe(filter(isActionOf(unenrollAuthenticatorActions.cancel))),
        ),
      ),
    ),
  );

export const authenticatorsEpic = combineEpics(
  listAuthenticatorsEpic,
  enrollAuthenticatorEpic,
  unenrollAuthenticatorEpic,
);
