// Copyright 2022, Imprivata, Inc.  All rights reserved.

import i18n, { InitOptions } from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import LocalResourcesBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translations from './translations/en.json';
import { initReactI18next } from 'react-i18next';
import deviceModelTranslations from './translations/deviceModel.en.json';

export enum ContextNames {
  ASSURANCE = 'assurance',
  AUTHENTICATORS = 'authenticators',
  LOGIN = 'login',
  SUPERVISOR = 'supervisor',
  IID_ENROLLMENT = 'iid-enrollment',
  PIN_ENROLLMENT = 'pin-enrollment',
  DEVICE_MODEL = 'deviceModel',
}

export const defaultNS = 'translation' as const;
export const deviceModelNS = 'deviceModel' as const;

export const ns = [defaultNS, deviceModelNS];

export const resources = {
  en: {
    [defaultNS]: translations,
    [deviceModelNS]: deviceModelTranslations,
  },
} as const;

const i18nInstance = i18n.createInstance({
  debug: false,
  defaultNS,
  ns,
  lng: 'en',
  fallbackLng: 'en',
  returnEmptyString: false,

  backend: {
    backends: [
      // Load translations from json files in ./translations
      LocalResourcesBackend(resources),
    ],
  },

  detection: {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng',
  },

  react: {
    useSuspense: true,
  },
} as InitOptions);

void i18nInstance
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init();

export * from './localeProvider';

export { i18nInstance as i18n };
export default i18nInstance;
