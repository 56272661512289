// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Tracer, TracingSolutionType } from '@imprivata-cloud/common';
import getConfig from '../appConfigUtils';
export * from './constants';

const {
  OTLP_TRACING_URL,
  ZIPKIN_TRACING_URL,
  COLLECT_TRACING,
  TRACING_SOLUTION_TYPE,
} = getConfig();

const tracingSolutionType =
  TracingSolutionType[
    TRACING_SOLUTION_TYPE as keyof typeof TracingSolutionType
  ];

export const tracer = new Tracer(
  'user-web-ui',
  '',
  {
    logToConsole: true,
    otlpExportUrl: OTLP_TRACING_URL,
    zipkinExportUrl: ZIPKIN_TRACING_URL,
    collectSpans: !!COLLECT_TRACING,
  },
  undefined,
  ...(tracingSolutionType !== undefined ? [tracingSolutionType] : []),
);
